.california h5{
    color: #008bd0;
    margin-top: 20px;
    margin-bottom: 20px;
}

.california h2{
 
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.california a{
    color: #e73c3f;
}